<template>
    <div class="auto-1440" style="position: relative;">
        <Breadcrumb type="grey" :title="info.Title"/>
        <div class="publish_btn_box">
                <router-link to="/publish" class="publish_btn">发帖</router-link>
            </div>
        <a-spin :spinning="loading">
            <div class="title">
                <div class="info">
                    <h1><Tag v-if="subjectListNoTree.length > 0" :name="subjectListNoTree.find(subjectitem => subjectitem.Id == info.SubjectId)?.Title" /> {{info.Title}}</h1>
                    <div class="tag_warp">
                        <Tag v-for="item in modelList" :name="item.Name" :key="item.Id" type="green"/>
                    </div>
                </div>
                <div class="handel">
                    <div class="item">
                        <svg-icon icon-class="icon_eye"></svg-icon>
                        <span>{{info.Hits}}</span>
                    </div>
                    <div class="item">
                        <svg-icon icon-class="icon_comment"></svg-icon>
                        <span>{{info.Reply}}</span>
                    </div>
                    <div class="item collect" :class="info.IsCollection ? 'active':''" @click="ToggleCollectPost">
                        <a-tooltip >
                            <template slot="title">
                                <span v-if="!info.IsCollection">收藏</span>
                                <span v-else>取消收藏</span>
                            </template>
                            <svg-icon icon-class="icon_collect"></svg-icon>
                        </a-tooltip>
                        
                    </div>
                </div>
            </div>
            
            <div class="floor_list">
                
                
            
                <template v-for="item in list">
                    <Floor 
                        :key="item.Id" 
                        :data="item"
                        @seeAuthor="onlySeeAuthor"
                        @reply="reply"
                        @update="getlist"
                    />
                </template>
            
            </div>
        </a-spin>
        <Pagination :total="total" :pageSize="params.pageSize" @pageChange="pageChange" />
        <div id="replayEditor">
            <Editor v-model="replyForm.Content" ref="editor"/>
        </div>
        <div class="btn">
            <button @click="replySunbit">回复</button>
        </div>
        <TipDialog :visable="visable" @close="visable = false" :loading="confirmLoading" @confirm="confirmPublish"/>
    </div>
</template>
<script>
import Breadcrumb from '@/components/common/Breadcrumb' 
import Tag from '@/components/common/Tag' 
import Floor from '@/components/Forum/Info/Floor' 
import Pagination from '@/components/common/Pagination' 
import Editor from '@/components/common/Editor' 
import TipDialog from '@/components/common/TipDialog'

import { GetData , GetListById  , Reply} from '@/api/forum'
import { GetListByIds } from '@/api/model'
import { ToggleCollectPost } from '@/api/collection'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
    metaInfo() {
		return {
			title: '文物出版社',
			meta: [
                {
                    name: 'keyWords',
					content: '文物出版社'
                },
                {
                    name: 'description',
					content: '文物出版社'
                }
            ]
		}
	},
    components:{
        Breadcrumb,
        Tag,
        Floor,
        Pagination,
        Editor,
        TipDialog
    },
    
    data(){
        return {
            info:{
                Title:'文保法中程序的归纳',
                Tags:'法律法规与工程管理,行政法,行政法基础'
            },
            params:{
                id:null,
                pageIndex:1,
                pageSize:3,
                isAuthor:false
            },
            list:[],
            total:0,
            loading:false,

            replyForm:{
                Id:null,
                Content:''
            },
            visable:false,
            confirmLoading:false,
            subjectListNoTree:[],
            modelList:[]
        }
    },
    // ...mapState({
    //     subjectListNoTree:state => state.model.subjectListNoTree,
    // }),
    methods:{
        // 获取回复列表
        async getlist(){
            this.loading = true
            const { data , total } = await  GetListById(this.params)
            console.log(data,total,'list');
            this.list = data
            this.total = total
            this.loading = false
        },
        // 获取详情
        async getData(){
            this.loading = true
            const { data } = await  GetData({id:this.params.id})
            console.log(data,'data');
            this.info = data
            if(data.ModelIds){
                this.getModelList()
            }
            
            this.loading = false
        },
        getModelList(){
            console.log(this.info.ModelIds);
            GetListByIds({ids:this.info.ModelIds}).then(res => {
                this.modelList = res.data
            })
        },
        pageChange({page,pageSize}){
            this.params.pageIndex = page
            this.getlist()
        },
        // 只看该作者
        onlySeeAuthor(){
            if(this.params.isAuthor) return
            this.params.pageIndex = 1;
            this.params.isAuthor = true;
            this.getlist()
        },

        // 回复
        reply(item){
            console.log(item)
            let txt = `<a style="color:#f49133" href="#floor${item.Floor}">#${item.Floor}楼</a> &nbsp; <span style="color:#4545e2;">@${item.UserInfo.Nickname || item.UserInfo.TrueName}</span> &nbsp;&nbsp;&nbsp;&nbsp;`
            this.replyForm.Content = txt
            this.$nextTick(() => {
                document.getElementById('replayEditor').scrollIntoView({behavior: "smooth"})
                this.$refs.editor.editorFocus()
            })
            
        },
        replySunbit(){
            if(!this.replyForm.Content ) return this.$message.error('请输入回复内容')
            // console.log(123);
            this.visable = true
        },
        confirmPublish(){
            this.confirmLoading = true
            var scriptReg = /(<|&lt;)script.*script(>|&gt;)/
            this.replyForm.Content = this.replyForm.Content.replace(scriptReg,"");
            Reply(this.replyForm).then(res => {
                this.$message.success(res.msg)
                this.replyForm.Content = ''
                this.getlist()
                this.confirmLoading = false
                this.visable = false
            }).catch(e => {
                this.confirmLoading = false
                this.visable = false
            })
        },
        // 收藏
        ToggleCollectPost(){
            ToggleCollectPost(this.$route.params.id).then(res => {
                this.$message.success(res.msg)
                this.info.IsCollection  = !this.info.IsCollection 
            }).catch(err => {

            })
        }
    },
    async created(){
        this.replyForm.Id = this.$route.params.id
        this.params.id = this.$route.params.id
        this.getData()
        this.getlist()
        await this.$store.dispatch('model/getSubject')
        this.subjectListNoTree = this.$store.state.model.subjectListNoTree
    }
}
</script>
<style lang="less" scoped>
:deep(.breadcrumb){
    border-bottom: 0;
    margin-bottom: 10px;
}
.auto-1440{
    padding-bottom: 80px;
    // position: relative;
}
.title{
    padding-bottom: 10px;
    border-bottom: 1px solid #d5d5d5;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;
    h1{
        font-size: 20px;
        color: rgb(51, 51, 51);
        line-height: 1.5;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .handel{
        flex-shrink: 0;
        display: flex;
        align-items: center;
        
        .item{
            margin-bottom: 10px;
            line-height: 30px;
            font-size: 18px;
            color: rgb(136, 136, 136);
            display: flex;
            align-items: center;
            margin-left: 30px;
            transition: .3s;
            .svg-icon{
                margin-right: 10px;
                color: #bcbcbc;
                transition: .3s;
            }
            &.active{
                color: #f49133;
                .svg-icon{
                    color: #f49133;
                }
            }
        }
        .collect{
           display: flex;
            cursor: pointer;
            align-items: center;
            &:hover{
                color: #f49133;
                .svg-icon{
                    color: #f49133;
                }
            }
        }
    }
}
.floor_list{
    margin-bottom: 50px;
    // position: relative;
    
}
.publish_btn_box{
    position: absolute;
    right: -80px;
    top: 130px;
    height: 100%;
}
.publish_btn{
    width: 60px;
    height: 60px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    background-color: #e5e5e5;
    font-size: 18px;
    color: rgb(136, 136, 136);
    transition: .3s;
    cursor: pointer;
    position: sticky;
    top: 100px;
    right: 0;        
    &:hover{
        color: #000;
        background-color: #00ffc0;
    }
}

:deep(.pagenation){
    margin-bottom: 60px;
}
.btn{
    text-align: right;
    margin-top: 20px;
    button{
        text-align: center;
        min-width: 120px;
        line-height: 40px;
        font-size: 18px;
        color: #fff;
        border-radius: 3px;
        background-color: rgb(244, 145, 51);
        cursor: pointer;
        transition: .3s;
        &:hover{
            box-shadow: 0 0 10px 0 rgb(244, 145, 51 , .8);
        }
    }
}

:deep(.pagenation){
    margin-bottom: 0;
}
#replayEditor{
    padding-top: 60px;
}
</style>