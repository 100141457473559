<template>
    <div class="floor" :id="'floor'+ data.Floor">
        <div class="pic">
            <div class="landlord" v-if="userinfo?.IsFisrt"><span>楼主</span></div>
            <a-avatar :size="100" :src="userinfo?.ImageUrl">
                <svg-icon class="icon_user" icon-class="icon_user2" ></svg-icon>
            </a-avatar>
            <div class="name">{{userinfo?.Nickname || userinfo?.TrueName}}</div>
            <div class="tag" v-if="userinfo?.Title">{{userinfo?.Title}}</div>
            <div class="per_data">
                <div class="item">
                    <span>帖子</span>
                    <span>{{userinfo?.Posts}}</span>
                </div>
                <div class="item">
                    <span>积分</span>
                    <span>{{userinfo?.Integral}}</span>
                </div>
            </div>
        </div>
        <div class="info">
            <div class="title_wrap">
                <div class="left">
                    
                    <div class="time">发表于 {{data.CreateTime.split(' ')[0].split('/').join('-')}}</div>
                    <div class="only_author" v-if="userinfo?.IsFisrt" @click="onlySeeAuthor">只看该作者</div>
                    <div class="floorNum" v-if="data.ParentId==0">#1</div>
                    <div class="floorNum" v-else>#{{data.Floor}}</div>
                </div>
                <!-- <div class="report">举报</div> -->
            </div>
            <div class="content" v-html="data.Content">
                
                <!-- <h1 style="font-size: 24px;color: rgb(51, 51, 51); margin-bottom:15px;">中华人民共和国文物保护法</h1>
                <p style="color: rgb(85, 85, 85);line-height: 1.75;">第一章总则</p>
                <p style="color: rgb(85, 85, 85);line-height: 1.75;">第一条 为了加强对文物的保护，继承中华民族优秀的历史文化遗产，促进科学研究工作，进行爱国主义和革命传统教育，建设社会主义精神文明和物质文明，根据宪法，制定本法。</p>
                <p style="color: rgb(85, 85, 85);line-height: 1.75;">第二条 在中华人民共和国境内，下列文物受国家保护：</p>
                <p style="color: rgb(85, 85, 85);line-height: 1.75;">（一）具有历史、艺术、科学价值的古文化遗址、古墓葬、古建筑、石窟寺和石刻、壁画；</p>
                <p style="color: rgb(85, 85, 85);line-height: 1.75;">（二）与重大历史事件、革命运动或者著名人物有关的以及具有重要纪念意义、教育意义或者史料价值的近代现代重要史迹、实物、代表性建筑；</p>
                <p style="color: rgb(85, 85, 85);line-height: 1.75;">（三）历史上各时代珍贵的艺术品、工艺美术品；</p>
                <p style="color: rgb(85, 85, 85);line-height: 1.75;">（四）历史上各时代重要的文献资料以及具有历史、艺术、科学价值的手稿和图书资料等；</p>
                <p style="color: rgb(85, 85, 85);line-height: 1.75;">（五）反映历史上各时代、各民族社会制度、社会生产、社会生活的代表性实物。</p>
                <p style="color: rgb(85, 85, 85);line-height: 1.75;">文物认定的标准和办法由国务院文物行政部门制定，并报国务院批准。</p>
                <p style="color: rgb(85, 85, 85);line-height: 1.75;">具有科学价值的古脊椎动物化石和古人类化石同文物一样受国家保护。</p>
                <p style="color: rgb(85, 85, 85);line-height: 1.75;">第三条 古文化遗址、古墓葬、古建筑、石窟寺、石刻、壁画、近代现代重要史迹和代表性建筑等不可移动文物，根据它们的历史、艺术、科学价值，可以分别确定为全国重点文物保护单位，省级文物保护单位，市、县级文物保护单位。</p>
                <p style="color: rgb(85, 85, 85);line-height: 1.75;">历史上各时代重要实物、艺术品、文献、手稿、图书资料、代表性实物等可移动文物，分为珍贵文物和一般文物；珍贵文物分为一级文物、二级文物、三级文物。</p>
                <p><br></p>
                <img src="@/static/image/conetnt.png" alt=""> -->
            </div>
            <div class="handle">
                <div class="item" @click="reply">
                    <svg-icon icon-class="icon_comment"></svg-icon>
                    <span>回复</span>
                </div>
                <div class="item " @click="up" >
                    <svg-icon icon-class="icon_zan"></svg-icon>
                    <span>支持 {{data.Up || ''}}</span>
                </div>
                <div class="item " @click="down">
                    <svg-icon icon-class="icon_cai"></svg-icon>
                    <span>反对 {{data.Down || ''}}</span>
                </div>
                
                    <a-popconfirm
                        title="是否继续删除该评论？"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="deleteFloor"
                        v-if="selfuserInfo.IsSpecial && data.ParentId!==0"
                    >
                        <div class="item "  >
                            <svg-icon icon-class="icon_delete2"></svg-icon>
                            <span>删除</span>
                        </div>
                    </a-popconfirm>
                    
                
                <!-- <div class="item">
                    <svg-icon icon-class="icon_collect"></svg-icon>
                    <span>收藏</span>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import { GetData , GetListById , Up , Down , Del } from '@/api/forum'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
    props:['data'],
    computed:{
        userinfo(){
            return this.data.UserInfo
        },
        ...mapState({
            selfuserInfo: state => state.user.userInfo,
            subjectListNoTree:state => state.model.subjectListNoTree,
        }),
    },
    data(){
        return{

        }
    },
    methods:{
        // 支持
        up(){
            Up({id:this.data.Id}).then(res => {
                this.$message.success(res.msg)
                this.data.Up = res.data
            }).catch(e => e)
        },
        down(){
            Down({id:this.data.Id}).then(res => {
                this.$message.success(res.msg)
                this.data.Down = res.data
            }).catch(e => e)
        },
        deleteFloor(){
            Del({id:this.data.Id}).then(res => {
                this.$message.success(res.msg)
                this.$emit('update')
            }).catch(e => e)
        },
        // 只看该作者
        onlySeeAuthor(){
            this.$emit('seeAuthor')
        },
        // 回复
        reply(){
            this.$emit('reply',this.data)
            // document.getElementById('replayEditor').scrollIntoView({behavior: "smooth"})
        }
    }
}
</script>
<style scoped lang="less">

.floor{
    display: flex;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #d5d5d5;
    .pic{
        width: 240px;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f5f5f5;
        padding: 40px;
        position: relative;
        overflow: hidden;
        :deep(.ant-avatar){
            margin-bottom: 20px;
        }
        :deep(.icon_user){
            font-size: 100px;
        }
        .name{
            font-size: 20px;
            color: rgb(0, 0, 0);
            margin-bottom: 10px;
        }
        .tag{
            min-width: 120px;
            line-height: 30px;
            border-radius: 15px;
            background-color: #f49133;
            color: #fff;
            text-align: center;
            padding: 0 15px;
            font-size: 16px;
            margin-bottom: 30px;
        }
        .per_data{
            min-width: 100px;
            font-size: 16px;
            color: rgb(85, 85, 85);
            line-height: 1;
            .item{
                margin-bottom: 10px;
            }
            span{
                display: inline-block;
                &:first-of-type{
                    min-width: 50px;
                    padding-right: 5px;
                    border-right: 1px solid #bbbbbb;
                }
                &:last-of-type{
                    padding-left: 15px;
                }
            }
        }
        .landlord{
            width: 70px;
            height: 70px;
            background-color: #f49133;
            color: #fff;
            text-align: center;
            transform: rotate(45deg);
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 45px;
            position: absolute;
            left: -35px;
            top: -35px;
            span{
                display: block;
                transform: rotate(-90deg);
                white-space: nowrap;
                // transform-origin: 100% 100%;
            }
        }
    }
    .info{
        padding-left: 50px;
        flex: 1;
        .title_wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px 0;
            border-bottom: 1px solid #e5e5e5;
            margin-bottom: 30px;
            .left{
                display: flex;
                align-items: center;
                font-size: 16px;
                line-height: 1;
            }
            .time{
                color: rgb(136, 136, 136);
                padding-right: 15px;
                border-right: 1px solid #e5e5e5;
            }
            .floorNum{
                margin-left: 15px;
            }
            .only_author{
                padding-left: 15px;
                color: rgb(255, 0, 0);
                cursor: pointer;
            }
            .report{
                color: rgb(136, 136, 136);
                cursor: pointer;
            }
        }
        .content{
            padding-bottom: 50px;
            border-bottom: 1px solid #e5e5e5;
            margin-bottom: 20px;
            img{
                height:  auto !important;
            }
        }
        .handle{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 68px;
            .item{
                margin-left: 40px;
                font-size: 16px;
                display: flex;
                align-items: center;
                cursor: pointer;
                .svg-icon{
                    color: #bbb;
                    transition: .3s;
                    margin-right: 10px;
                    display: block;
                }
                span{
                    color: rgb(85, 85, 85);
                    transition: .3s;
                }
                &.active,
                &:hover{
                    .svg-icon{
                        color: #f49133;
                    }
                    span{
                        color: #f49133;
                    }
                }
                &.active.object{
                    .svg-icon{
                        color: #333;
                    }
                    span{
                        color: #333;
                    }
                }
            }
        }
    }
}


</style>