<template>
    <a-modal
        v-model="dialogVisable"
        centered
        :footer="null"
        :width="800"
        class="tipDialog"
        @cancel="closeDialog"
        :destroyOnClose="true"
    >
        <div class="title">
            <span>温馨提示</span>
        </div>
        <div class="content">
            本平台发布的一切内容，需通过管理员审核后，方可呈现。是否通过审核请在个人中心我的消息中查看。
        </div>
        <div class="dialog_footer">
            <a-button class="confirm" :loading="loading" @click="confirm">接受</a-button>
            <a-button class="cancel" @click="closeDialog">不接受</a-button>
        </div>
    </a-modal>
</template>
<script>
export default {
    props:{
        visable:{
            type:Boolean,
            default:false
        },
        loading:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            dialogVisable:false,
        }
    },
    watch:{
        visable:{
            handler(newVal,oldVal){
                this.dialogVisable = newVal
            },
            immediate:true
        }
    },
    methods:{
        closeDialog(){
            this.$emit('close')
        },
        confirm(){
            this.$emit('confirm')
        }
    }
}
</script>
<style lang="less" scoped>
:deep(.ant-modal) {
    width: 1000px !important;
}
.tipDialog {
    :deep(.ant-modal-body) {
        padding: 60px 200px 50px;
        position: relative;
    }
    :deep(.ant-form-item-children) {
        display: flex;
    }
}
.title {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 45px;
    span {
        padding-bottom: 26px;
        line-height: 1;
        position: relative;
        font-size: 32px;
        color: rgb(51, 51, 51);
        &::before {
            content: '';
            width: 100px;
            height: 5px;
            background-color: #00ffc0;
            border-radius: 2px;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
        }
    }
}
.content{
    text-align: center;
    font-size: 18px;
    color: rgb(51, 51, 51);
    line-height: 1.667;
    padding-top: 15px;
    margin-bottom: 30px;
}
@media screen and (min-width: 1800px  /*no*/) and(max-height:800px  /*no*/) {
    :deep(.ant-modal-content) {
        transform: scale(0.8);
    }
}
</style>