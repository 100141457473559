<template>
    <div class="editor" @mouseup.stop="mouseUp($event)">
        <!-- <editor :data-id="id" v-model="content"  :init="init" ></editor> -->
        <vue-tinymce
            :setting="init"
            v-model="content"
            @change="updateCalue"
            ref="tinymce"
            :setup="setup"
            id="editor"
        ></vue-tinymce>
    </div>
</template>
<script>
// import Editor from "@tinymce/tinymce-vue";
// import '@/utils/tinymce/langs/zh_CN.js'
import { getOSSUpload } from '@/api/common'
export default {
    props: {
        value: String,
        isEditable: {
            type: Boolean,
            default: false,
        },
        id: [String, Number],
    },
    components: {
        // Editor
    },
    data(vm) {
        return {
            init: {
                readonly: true,
                toolbar: false,
                inline: true,
                toolbar_drawer: false, //折叠toolbar
                menubar: true,
                // toolbar: 'code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor |  alignleft aligncenter alignright alignjustify outdent indent | ',
                toolbar:
                    'code undo redo restoredraft | code cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link | alignleft aligncenter alignright alignjustify outdent indent indent2em lineheight| \
                     formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \
                     table image charmap emoticons hr pagebreak insertdatetime print preview axupimgs | fullscreen ',
                plugins: `undo redo restoredraft code cut copy paste pastetext forecolor backcolor bold italic underline strikethrough link anchor alignleft aligncenter alignright alignjustify outdent indent indent2em lineheight
                     styleselect formatselect fontselect fontsizeselect bullist numlist blockquote subscript superscript removeformat
                     table image charmap emoticons hr pagebreak insertdatetime print preview fullscreen wordcount lists advlist link axupimgs`,
                quickbars_selection_toolbar:
                    'removeformat | bold italic underline strikethrough | link  fontsizeselect forecolor backcolor | quickimage | mybutton',
                fontsize_formats:
                    '12px 14px 16px 18px 24px 36px 48px 56px 72px',
                font_formats:
                    '思源黑体=Source Han Sans;微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;',
                language: 'zh_CN', // 语言
                branding: false, //右下角技术支持
                height: 500, //高度
                paste_data_images: true,
                elementpath: false,
                toolbar_sticky: true,
                visualchars_default_state: true,
                table_responsive_width: false,
                table_default_attributes: { border: '1' },
                paste_auto_cleanup_on_paste: true,
                paste_remove_styles: true,
                paste_remove_styles_if_webkit: true,
                paste_strip_class_attributes: true,
                image_dimensions: false,
                valid_elements: '*[*]',
                valid_children: '*[*]',
                extended_valid_elements: 'style,link[href|rel],script',
                custom_elements: 'style,link,~link,script',
                file_picker_callback: function (callback, value, meta) {
                    //文件分类
                    let filetype = ''
                    //后端接收上传文件的地址
                    let url = ''
                    //为不同插件指定文件类型及后端地址
                    switch (meta.filetype) {
                        case 'image':
                            filetype = '.jpg,.jpeg,.png,.gif,.tiff'
                            break
                        case 'file':
                            let filetype =
                                '.pdf,.txt,.zip,.rar,.7z,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.mp3,.mp4'
                            break
                        case 'media':
                            iletype = '.mp3,.mp4'
                            break
                    }
                    url = window.configObj.ossURL
                    //模拟出一个input用于添加本地文件
                    let input = document.createElement('input')
                    input.setAttribute('type', 'file')
                    input.setAttribute('accept', filetype)
                    input.click()
                    input.onchange = function () {
                        let file = this.files[0]
                        let fd = new FormData() // FormData 对象
                        let config = {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }
                        getOSSUpload({ fileName: file.name }).then(res => {
                            fd.append('key', res.data.key)
                            fd.append('policy', res.data.policy)
                            fd.append('OSSAccessKeyId', res.data.accessid)
                            fd.append('success_action_status', '200') //让服务端返回200,不然，默认会返回204
                            fd.append('callback', res.data.callback)
                            fd.append('signature', res.data.signature)
                            fd.append('file', file) // 文件对象
                            axios.post(url, fd, config).then(res => {
                                callback(res.data.location)
                            })
                        })
                    }
                },
                images_upload_handler: function (blobInfo, succFun, failFun) {
                    let file = blobInfo.blob() //转化为易于理解的file对象
                    let url = 'https://xuexi-001.oss-cn-beijing.aliyuncs.com'
                    let fd = new FormData() // FormData 对象
                    let config = {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                    getOSSUpload({ fileName: file.name }).then(res => {
                        fd.append('key', res.data.key)
                        fd.append('policy', res.data.policy)
                        fd.append('OSSAccessKeyId', res.data.accessid)
                        fd.append('success_action_status', '200') //让服务端返回200,不然，默认会返回204
                        fd.append('callback', res.data.callback)
                        fd.append('signature', res.data.signature)
                        fd.append('file', file) // 文件对象
                        axios.post(url, fd, config).then(res => {
                            succFun(res.data.location)
                        })
                    })
                },
                files_upload_handler: function (blobInfo, succFun, failFun) {
                    let file = blobInfo.blob() //转化为易于理解的file对象
                    let url = 'https://xuexi-001.oss-cn-beijing.aliyuncs.com'
                    let fd = new FormData() // FormData 对象
                    let config = {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                    getOSSUpload({ fileName: file.name }).then(res => {
                        fd.append('key', res.data.key)
                        fd.append('policy', res.data.policy)
                        fd.append('OSSAccessKeyId', res.data.accessid)
                        fd.append('success_action_status', '200') //让服务端返回200,不然，默认会返回204
                        fd.append('callback', res.data.callback)
                        fd.append('signature', res.data.signature)
                        fd.append('file', file) // 文件对象
                        axios.post(url, fd, config).then(res => {
                            succFun(res.data.location)
                        })
                    })
                },
            },
            editor: null,
            content: '',
        }
    },
    methods: {
        updateCalue() {
            this.$emit('input', this.content)
            this.$emit('change', this.content)
        },
        setup(editor) {
            // console.log(editor.editorManager.get("editor").focus());
            this.editor = editor
        },
        // 聚焦
        editorFocus() {
            this.editor.editorManager.get(this.editor.id).focus()
        },
        mouseUp(e) {
            let str = `07）</p>
<p>《石质文物保护工程勘察规范》WW/T00632015</p>
<p>《石质文物保护修复方案编写规范》（WW/T0007-2007）`
            let content = this.editor.selection.getContent()
            let start = this.editor.selection.getStart()
            let end = this.editor.selection.getEnd()

            let selection = window.getSelection()
            let contentStarHasp = content.substr(0, 3).indexOf('p')
            let contentEndHasp = content.substr(-4, 4).indexOf('p')
            // if (
            //     contentStarHasp !== -1 &&
            //     this.editor.selection.getRng().startOffset !== 0
            // ) {
            //     content = content.substr(3)
            // }
            // if (
            //     contentEndHasp !== -1 &&
            //     this.editor.selection.getRng().endOffset !==
            //         this.editor.selection.getRng().endContainer.data.length
            // ) {
            //     content = content.substr(0, content.length - 4)
            // }
            let before = ''
            let after = ''
            let html = this.editor.selection.getContent({ format: 'html' })
            console.log(this.editor.selection)
        },
    },
    watch: {
        value: {
            handler(newVal, oldVal) {
                this.content = newVal
            },
            immediate: true,
        },
    },
}
</script>
<style scoped lang="less">
// /deep/.mce-edit-focus{
//     outline: none;
// }
// .editor{
//     padding: 15px 20px 20px;
//     font-size: 16PX;
//     white-space: normal;
// }
</style>
