import { render, staticRenderFns } from "./_Info.vue?vue&type=template&id=4df10458&scoped=true&"
import script from "./_Info.vue?vue&type=script&lang=js&"
export * from "./_Info.vue?vue&type=script&lang=js&"
import style0 from "./_Info.vue?vue&type=style&index=0&id=4df10458&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4df10458",
  null
  
)

export default component.exports